import store from '@/store'
import router from '@/router'
import merchantStoreModule from '@/pagsmile/merchantStoreModule'
import transactionStoreModule from '@/pagsmile/transactionStoreModule'

export default function transactionsJs() {
  const MERCHANT_STORE_MODULE_NAME = 'merchant'
  const TRANSACTION_STORE_MODULE_NAME = 'transaction'

  // Register module
  if (!store.hasModule(MERCHANT_STORE_MODULE_NAME)) store.registerModule(MERCHANT_STORE_MODULE_NAME, merchantStoreModule)
  if (!store.hasModule(TRANSACTION_STORE_MODULE_NAME)) store.registerModule(TRANSACTION_STORE_MODULE_NAME, transactionStoreModule)

  const fetchMerchants = (vm, callback) => {
    store
      .dispatch('merchant/fetchDropDownMerchants')
      .then(response => {
        const { code, data } = response.data
        const merchants = []

        if (code === '10000') {
          data.forEach(item => {
            const merchant = { label: item.merchant_name, value: item.merchant_no }
            merchants.push(merchant)
          })
        }

        callback(merchants)
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching Merchants', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const fetchMerchantApps = (vm, merchantNo, callback) => {
    store
      .dispatch('merchant/fetchDropDownAppsMerchant', merchantNo)
      .then(response => {
        const { code, data } = response.data
        const apps = []

        if (code === '10000') {
          data.forEach(item => {
            const app = { label: `${item.app_name}(${item.app_region})`, value: item.app_id }
            apps.push(app)
          })
        }
        callback(apps)
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching Merchant APPs', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const fetchChannelFilters = (vm, callback) => {
    store
      .dispatch('merchant/fetchChannelFilters')
      .then(response => {
        const { code, data } = response.data

        if (code === '10000') {
          callback(data)
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching Channels', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const fetchPayChannelListDetail = (vm, callback) => {
    store
      .dispatch('merchant/fetchPayChannelListDetail')
      .then(response => {
        const { code, data } = response.data

        if (code === '10000') {
          callback(data)
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching Channel List Detail', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const searchTransactions = (vm, params, callback) => {
    store
      .dispatch('merchant/fetchTransactions', params)
      .then(response => {
        const { code, data, msg } = response.data

        if (code === '10000') {
          callback(data)
          vm.$bvToast.toast('Success fetching Transactions', {
            title: 'Success',
            icon: 'AlertTriangleIcon',
            variant: 'success',
            toaster: 'b-toaster-top-right',
            solid: true,
          })
        } else {
          callback('')
          vm.$bvToast.toast(msg, {
            title: 'Alerts',
            icon: 'AlertTriangleIcon',
            variant: 'success',
            toaster: 'b-toaster-top-right',
            solid: true,
          })
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching Transactions', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const downloadTransactions = (vm, params) => {
    store
      .dispatch('merchant/downloadTransactions', params)
      .then(response => {
        const { code } = response.data

        if (code === '10000') {
          vm.$bvToast.toast('Download Transactions Request Submitted', {
            title: 'Success',
            icon: 'AlertTriangleIcon',
            variant: 'success',
            toaster: 'b-toaster-top-right',
            solid: true,
          })

          setTimeout(() => {
            router.push({
              name: 'transactions-download-log',
            })
          }, 1000)
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error Download Transactions', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })

        router.push({
          name: 'transactions-download-log',
        })
      })
  }

  const fetchDownloadList = (vm, callback) => {
    store
      .dispatch('merchant/fetchDownloadList')
      .then(response => {
        const { code, data } = response.data

        if (code === '10000') {
          callback(data)
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching Download List', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const fetchTransactionDetail = (params, success, fail) => {
    store
      .dispatch('transaction/fetchTransactionDetail', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const revokeRefund = (params, success, fail) => {
    store
      .dispatch('transaction/revokeRefund', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const refundConfirm = (params, success, fail) => {
    store
      .dispatch('transaction/refundConfirm', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const callback = (params, success, fail) => {
    store
      .dispatch('transaction/callback', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const fillOrder = (params, success, fail) => {
    store
      .dispatch('transaction/fillOrder', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const searchRefundTransactions = (params, success, fail) => {
    store
      .dispatch('transaction/fetchRefundTransactions', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const cancelOrder = (params, success, fail) => {
    store
      .dispatch('transaction/cancelOrder', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const recheckOrder = (params, success, fail) => {
    store
      .dispatch('transaction/recheckOrder', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const renotifyOrder = (params, success, fail) => {
    store
      .dispatch('transaction/renotifyOrder', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const revealCard = (params, success, fail) => {
    store
      .dispatch('transaction/revealCard', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchDropProviders = (vm, callback1) => {
    store
      .dispatch('merchant/fetchDropProviders')
      .then(response => {
        const { code, data } = response.data
        const providers = []
        if (code === '10000') {
          data.forEach(item => {
            const provider = { label: item.provider_name, value: item.provider_id }
            providers.push(provider)
          })
        }
        callback1(providers)
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching Providers', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const searchTransactionsByFilter = (params, success, fail) => {
    store
      .dispatch('transaction/fetchTransactionsByFilter', params)
      .then(response => {
        const { code, data, msg } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const downloadTransactionsByFilter = (params, success, fail) => {
    store
      .dispatch('transaction/downloadTransactionsByFilter', params)
      .then(response => {
        const { code, data, msg } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  return {
    fetchMerchants,
    fetchMerchantApps,
    fetchChannelFilters,
    fetchPayChannelListDetail,
    searchTransactions,
    downloadTransactions,
    fetchDownloadList,
    fetchTransactionDetail,
    revokeRefund,
    refundConfirm,
    callback,
    fillOrder,
    searchRefundTransactions,
    cancelOrder,
    recheckOrder,
    renotifyOrder,
    revealCard,
    fetchDropProviders,
    searchTransactionsByFilter,
    downloadTransactionsByFilter,
  }
}
