<template>
  <b-sidebar
    id="app-config-sidebar"
    :visible="isAppConfigSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initSidebarData"
    @change="(val) => $emit('update:is-app-config-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add APP Configuration
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer ref="simpleRules">
        <b-form class="p-2">

          <!-- Config Key -->
          <b-form-group
            label="Config Key"
            label-for="config-key"
          >
            <v-select
              v-model="configKey"
              :options="configKeyOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="config-key"
            />
          </b-form-group>
          <div v-if="configKey === 'EXCHANGE_RATE:ORDER'">
            <!-- Source Currency -->
            <b-form-group
              label="Source Currency"
              label-for="source-currency"
            >
              <v-select
                v-model="sourceCurrency"
                input-id="source-currency"
                :options="currencyOptions"
                :clearable="false"
                :searchable="false"
              />
            </b-form-group>

            <!-- Target Currency -->
            <b-form-group
              label="Target Currency"
              label-for="target-currency"
            >
              <v-select
                v-model="targetCurrency"
                input-id="target-currency"
                :options="currencyOptions"
                :clearable="false"
                :searchable="false"
              />
            </b-form-group>

            <!-- Exchange Rate Type -->
            <b-form-group
              label="Exchange Rate Type"
              label-for="exchange-rate-type"
            >
              <v-select
                v-model="exchangeRateType"
                input-id="exchange-rate-type"
                :options="exchangeRateTypeOptions"
                :reduce="val => val.value"
                :clearable="false"
                :searchable="false"
              />
            </b-form-group>
          </div>
          <div v-if="configKey === 'GOOGLE_RECAPTCHA'">
            <b-form-group
              label="Method"
              label-for="method"
            >
              <v-select
                v-model="chooseMethod"
                input-id="score-level"
                :options="paymentMethods"
                :clearable="false"
                :searchable="false"
              />
            </b-form-group>
            <b-form-group
              label="Score Level"
              label-for="score-level"
            >
              <v-select
                v-model="chooseLevel"
                input-id="score-level"
                :options="scoreLevel"
                :clearable="false"
                :searchable="false"
              />
            </b-form-group>
          </div>
          <!-- Config Value -->
          <div v-if="configKey === 'EXCHANGE_RATE:ORDER' || configKey ==='ORIGINAL_SOURCE_CHECK_RATE'">
            <validation-provider
              #default="{ errors }"
              name="Config Value"
              rules="required"
            >
              <b-form-group
                label="Config Value"
                label-for="config-value"
              >
                <b-form-input
                  id="config-value"
                  v-model="configValue"
                  type="number"
                  min="0"
                  autofocus
                  trim
                  :state="errors.length > 0 ? false : null"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>
          <div v-if="configKey === 'API_RESTRICTIONS'">
            <!-- APIs -->
            <b-form-group
              label="End Point"
              label-for="api-options"
            >
              <v-select
                v-model="endPoint"
                input-id="api-options"
                :options="apiOptions"
                :clearable="false"
                :searchable="false"
                :reduce="val => val.value"
                multiple
              />
            </b-form-group>
          </div>

          <el-divider class="mt-3 mb-3" />

          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="!isFormValid"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { showAlert, showToast } from '@/libs/tool'
import useMerchantsJs from './merchants'
import useTransacitonsJs from '../../transactions/transactions'

const {
  fetchChannelFilters,
} = useTransacitonsJs()

const {
  addAppConfig,
  getRegions,
} = useMerchantsJs()

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAppConfigSidebarActive',
    event: 'update:is-app-config-sidebar-active',
  },
  props: {
    isAppConfigSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      appId: '',
      configKey: 'EXCHANGE_RATE:ORDER',
      countries: [],
      regions: {},
      globalCountry: [],
      sourceCurrency: 'BRL',
      targetCurrency: 'BRL',
      exchangeRateType: 'F',
      configValue: 0,
      endPoint: '',
      error: '',
      chooseLevel: '0.5',
      chooseMethod: 'CreditCard',

      configKeyOptions: [
        { label: 'Order Exchange Rate', value: 'EXCHANGE_RATE:ORDER' },
        { label: 'Original Source Check Rate', value: 'ORIGINAL_SOURCE_CHECK_RATE' },
        { label: 'API Restrictions', value: 'API_RESTRICTIONS' },
        { label: 'Google reCAPTCHA Verification', value: 'GOOGLE_RECAPTCHA' },
        { label: 'Payer Verification', value: 'PAYER_VERIFICATION' },
      ],
      scoreLevel: [
        '0.1',
        '0.2',
        '0.3',
        '0.4',
        '0.5',
        '0.6',
        '0.7',
        '0.8',
        '0.9',
        '1.0',
      ],

      methodFilter: { type_id: '', type_name: '', type_desc: 'All' },
      paymentMethods: [],

      exchangeRateTypeOptions: [
        { label: 'Fixed Value', value: 'F' },
        { label: 'Additional Value', value: 'A' },
        { label: 'Percentage', value: 'P' },
      ],

      apiOptions: [
        { label: 'Checkout Redirect', value: 'WEB' },
        { label: 'API Direct', value: 'API' },
      ],

      required,
    }
  },
  computed: {
    isFormValid() {
      let ret = false
      if (this.configKey === 'EXCHANGE_RATE:ORDER') {
        ret = this.sourceCurrency !== '' && this.targetCurrency !== '' && this.exchangeRateType !== '' && this.configValue !== ''
      } else if (this.configKey === 'ORIGINAL_SOURCE_CHECK_RATE') {
        ret = this.configValue !== ''
      } else if (this.configKey === 'API_RESTRICTIONS') {
        ret = this.endPoint !== '' && this.endPoint.length > 0
      } else if (this.configKey === 'GOOGLE_RECAPTCHA') {
        ret = this.chooseLevel !== '' && this.chooseMethod !== ''
      } else if (this.configKey === 'PAYER_VERIFICATION') {
        ret = true
      }
      return ret
    },
    currencyOptions() {
      const currencyOptions = []
      const { currencies } = store.state.common
      currencies.forEach(item => {
        currencyOptions.push(item.code)
      })
      currencyOptions.push('%')
      return currencyOptions
    },
  },
  watch: {
    isAppConfigSidebarActive(val) {
      this.initSidebarData()
      if (!val) {
        return
      }
      this.appId = this.data.appId
    },
  },
  created() {
    fetchChannelFilters(this, filters => {
      const arr = []
      filters.methods.forEach(item => {
        if (item.type_name !== '' && item.type_name !== 'Other') {
          arr.push(item.type_name)
        }
      })
      arr.sort()
      this.paymentMethods = arr
      this.methodFilter = ''
    })
    const that = this
    getRegions(
      list => {
        that.countries = list.countries
        that.regions = list.regions
      },
      fail => {
        showToast(this, 'Warning', `fetch country and regions with ${fail}.`)
      },
    )
  },
  methods: {
    getRegionValue(val) {
      return `${val} -> ${this.regions[val]}`
    },
    initSidebarData() {
      this.appId = ''
      this.configKey = 'EXCHANGE_RATE:ORDER'
      this.sourceCurrency = 'BRL'
      this.targetCurrency = 'BRL'
      this.exchangeRateType = 'F'
      this.configValue = 0
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes!',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.addAppConfig()
            }
          })
        }
      })
    },
    addAppConfig() {
      let configKey
      let configValue
      if (this.configKey === 'EXCHANGE_RATE:ORDER') {
        configKey = `${this.configKey}:${this.sourceCurrency}:${this.targetCurrency}`
        configValue = `${this.exchangeRateType},${this.configValue}`
      } else if (this.configKey === 'ORIGINAL_SOURCE_CHECK_RATE') {
        configKey = this.configKey
        configValue = this.configValue
      } else if (this.configKey === 'API_RESTRICTIONS') {
        configKey = this.configKey
        configValue = this.endPoint === '' ? '' : this.endPoint.join(',')
      } else if (this.configKey === 'GOOGLE_RECAPTCHA') {
        configKey = `${this.configKey}:${this.chooseMethod}`
        configValue = this.chooseLevel
      } else if (this.configKey === 'PAYER_VERIFICATION') {
        configKey = this.configKey
        configValue = 'ON'
      } else {
        return
      }
      const params = {
        app_id: this.appId,
        config_key: configKey,
        config_value: configValue,
        status: 1,
      }
      addAppConfig(
        params,
        () => {
          showAlert(this, 'success', 'Updated!', 'Success')
          this.$emit('update:is-app-config-sidebar-active', false)
          this.$emit('updated')
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
