<template>

  <div>
    <!-- Add New Sidebar -->
    <app-config-sidebar
      :is-app-config-sidebar-active.sync="isAppConfigSidebarActive"
      :data="appInfo"
      @updated="queryConfigs"
    />

    <multi-currency-config-sidebar
      :is-multi-currency-sidebar-active.sync="isMultiCurrencySidebarActive"
      :data="appInfo"
      @updated="queryConfigs"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="outline-primary"
              :to="{ name: 'merchant-apps', query: { merchant_no: merchantNo }, params: { merchant_name: merchantName } }"
              class="mr-2"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                class="mr-50"
              />
              <span class="align-middle">Back</span>
            </b-button>
            <b-button
              variant="outline-primary"
              class="mr-2"
              @click="addNew"
            >
              <feather-icon
                icon="PlusIcon"
              />
              <span class="align-middle">Add New</span>
            </b-button>
            <b-button
              variant="outline-primary"
              @click="multiCurrencyConfig"
            >
              <feather-icon
                icon="GlobeIcon"
              />
              <span class="align-middle">Global Multi Country</span>
            </b-button>
          </b-col>
        </b-row>

      </div>

      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="configs"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatusText(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`config-row-${data.index}-delete-icon`"
              icon="Trash2Icon"
              size="16"
              class="cursor-pointer mx-1"
              @click="deleteConfirm(data.item)"
            />
          </div>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BButton, BBadge,
} from 'bootstrap-vue'
import useMerchantsJs from './merchants'
import AppConfigSidebar from './AppConfigSidebar.vue'
import MultiCurrencyConfigSidebar from './MultiCurrencyConfigSidebar.vue'

const {
  fetchAppConfigDetail,
  removeAppConfig,
} = useMerchantsJs()

export default {
  components: {
    AppConfigSidebar,
    MultiCurrencyConfigSidebar,
    BCard,
    BRow,
    BCol,
    BTable,
    BButton,
    BBadge,
  },
  data() {
    return {
      merchantNo: this.$route.query.merchant_no,
      merchantName: this.$route.params.merchant_name,
      appId: this.$route.query.app_id,
      appName: this.$route.params.app_name,
      configs: [],
      tableFields: [
        { key: 'config_key', label: 'SETTING KEY' },
        { key: 'config_value', label: 'SETTING VALUE' },
        { key: 'status', label: 'STATUS' },
        'actions',
      ],

      isAppConfigSidebarActive: false,
      isMultiCurrencySidebarActive: false,
      appInfo: {},
    }
  },
  watch: {
  },
  created() {
    this.queryConfigs()
  },
  methods: {
    showAlert(icon, title, text) {
      this.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton: `btn btn-${icon}`,
        },
      })
    },
    queryConfigs() {
      fetchAppConfigDetail(
        this.appId,
        configs => {
          this.configs = configs
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
    deleteConfirm(config) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteData(config)
        }
      })
    },
    deleteData(config) {
      removeAppConfig(
        config,
        () => {
          this.queryConfigs()
          this.showAlert('success', 'Deleted!', 'Configuration information deleted successfully')
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
    resolveStatusVariant(status) {
      if (status === 0) return 'warning'
      if (status === 1) return 'success'
      return 'secondary'
    },
    resolveStatusText(status) {
      if (status === 0) return 'Disabled'
      if (status === 1) return 'Enabled'
      return 'Unknown'
    },
    addNew() {
      this.appInfo = {
        appId: this.appId,
      }
      this.isAppConfigSidebarActive = true
    },
    multiCurrencyConfig() {
      const index = this.configs.filter(r => r.config_key === 'GLOBAL_REGIONS')
      if (index.length > 0) {
        this.appInfo = {
          appId: this.appId,
          config: index[0],
        }
      } else {
        this.appInfo = {
          appId: this.appId,
        }
      }
      this.isMultiCurrencySidebarActive = true
    },
  },
}
</script>
