<template>
  <b-sidebar
    id="multi-currency-config-sidebar"
    :visible="isMultiCurrencySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initSidebarData"
    @change="(val) => $emit('update:is-multi-currency-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add APP Configuration
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <div>
        <b-form>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group
                label="Country"
                label-for="source-currency"
              >
                <v-select
                  v-model="selectCountry"
                  input-id="source-currency"
                  :options="countries"
                  :clearable="false"
                  :searchable="false"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                label="Currency"
                label-for="target-currency"
              >
                <v-select
                  v-model="targetCurrency"
                  input-id="target-currency"
                  :options="currencies"
                  :clearable="false"
                  :searchable="false"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="12" class="text-center mt-2 mb-2">
              <b-button
                variant="success"
                class="btn-icon"
                @click="addToSelected"
              >
                <feather-icon icon="ChevronsDownIcon" />
              </b-button>
              <b-button
                variant="danger"
                class="btn-icon ml-2"
                @click="removeFromSelected"
              >
                <feather-icon icon="ChevronsUpIcon" />
              </b-button>
            </b-col>

            <b-col v-if="resources && resources.length > 0" cols="12" md="12">
              <b-table
                sticky-header="20rem"
                selectable
                select-mode="multi"
                :items="resources"
                :fields="fields"
                responsive="sm"
                @row-selected="onRowSelected"
              />
            </b-col>
          </b-row>
        </b-form>

        <el-divider class="mt-3 mb-3" />

        <div class="d-flex mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            type="submit"
            :disabled="!isFormValid"
            @click.prevent="validationForm"
          >
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BFormGroup, BButton, BForm, BRow, BCol, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { showAlert, showToast } from '@/libs/tool'
import useMerchantsJs from './merchants'

const {
  addAppConfig,
  getRegions,
} = useMerchantsJs()

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BButton,
    vSelect,
    BRow,
    BCol,
    BTable,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isMultiCurrencySidebarActive',
    event: 'update:isMultiCurrencySidebarActive',
  },
  props: {
    isMultiCurrencySidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      resources: [],
      fields: ['country', 'currency'],
      appId: '',
      configKey: 'EXCHANGE_RATE:ORDER',
      countries: [],
      regions: {},
      globalCountry: [],
      currencies: ['USD'],
      selectCountry: '',
      targetCurrency: '',
      exchangeRateType: 'F',
      configValue: 0,
      endPoint: '',
      error: '',
      chooseLevel: '0.5',
      chooseMethod: 'CreditCard',
      selected: [],
    }
  },
  computed: {
  },
  watch: {
    isMultiCurrencySidebarActive(val) {
      if (!val) {
        return
      }
      this.resources = []
      if (this.data.config) {
        Object.entries(JSON.parse(this.data.config.config_value)).forEach(entry => {
          const [key, value] = entry
          this.resources.push({ country: key, currency: value })
        })
      }
      this.appId = this.data.appId
    },
  },
  created() {
    const that = this
    getRegions(
      list => {
        that.countries = list.countries
        that.currencies = list.currencies
      },
      fail => {
        showToast(this, 'Warning', `fetch country and regions with ${fail}.`)
      },
    )
  },
  methods: {
    removeFromSelected() {
      this.selected.forEach(item => {
        const index = this.resources.findIndex(r => r.country === item.country)
        this.resources.splice(index, 1)
      })
    },
    onRowSelected(items) {
      this.selected = items
    },
    addToSelected() {
      // eslint-disable-next-line eqeqeq
      if (this.selectCountry == '' || this.targetCurrency == '') {
        return
      }
      const item = { country: this.selectCountry, currency: this.targetCurrency }
      const index = this.resources.filter(r => r.country === item.country)
      if (index.length > 0) {
        return
      }
      this.resources.push(item)
    },
    getRegionValue(val) {
      return `${val} -> ${this.regions[val]}`
    },
    initSidebarData() {
      this.appId = ''
      this.configKey = 'EXCHANGE_RATE:ORDER'
      this.sourceCurrency = 'BRL'
      this.targetCurrency = 'BRL'
      this.exchangeRateType = 'F'
      this.configValue = 0
    },

    isFormValid() {
      return this.resources.length > 0
    },
    validationForm() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.addAppConfig()
        }
      })
    },
    addAppConfig() {
      const configValue = {}
      this.resources.forEach(x => {
        configValue[x.country] = x.currency
      })
      const params = {
        app_id: this.appId,
        config_key: 'GLOBAL_REGIONS',
        config_value: JSON.stringify(configValue),
        status: 1,
      }
      addAppConfig(
        params,
        () => {
          showAlert(this, 'success', 'Updated!', 'Success')
          this.$emit('update:is-multi-currency-sidebar-active', false)
          this.$emit('updated')
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
